import React, { memo } from 'react';
import { SEO } from '@components';

import './enterprise-app-development.scss';
import {
  backendTechnologies,
  frontendTechnologies,
  mobileTechnologies,
  overviewItems,
  oodenSAS,
} from '@content';
import { LandingTechnology, LandingOverview, LandingWork } from '@containers';

const EnterpriseAppDevelopment = () => (
  <div>
    <SEO title="Enterprise application development" />
    <div className="on-wwd-main">
      <div className="on-wwd-container">
        <div className="on-wwd-text">
          <h1>Enterprise application development</h1>
          <p>
            We help global brands design, build & launch digital products on mobile, web & connected
            platforms.
          </p>
          <p>
            It’s not our style to build a digital product and then run away. To be truly successful,
            it needs to fit your business objectives and smoothly integrate with what you’ve already
            got.
            There needs to be a strategy in place so that your new platform or product can scale and
            grow with your business. We’re experts at delivering all this and more.
          </p>
        </div>
      </div>
      <div className="on-wwd-text on-wwd-item">
        <h1>Why ooden ?</h1>
        <LandingOverview text="" items={oodenSAS} />
      </div>
      <LandingTechnology text="Backend" items={backendTechnologies} />
      <LandingTechnology text="Frontend" items={frontendTechnologies} />
      <LandingTechnology text="Mobile" items={mobileTechnologies} />
      <div className="on-wwd-text"><h1>{' '}</h1>
        <LandingWork text="All you need is the right partner. We can be that partner." />
        
        <LandingOverview text="How we can generally help" items={overviewItems} />
      </div>

      <div className="on-wwd-padding" />
    </div>
  </div>
);

export default memo(EnterpriseAppDevelopment);
